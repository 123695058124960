.book {
  display: flex;
  flex-direction: row;
  align-content: space-around;
}

.page {
  flex: 1;
  min-height: 750px;
}

.page-selection {
  margin-left: 8px !important;
  margin-right: 8px !important;
}