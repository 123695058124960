.recentbook-delete {
    background-color: #d32f2f !important;
}

.recentbook-delete:hover {
    background-color: #c62828 !important;
}

.recentbook-delete .delete-icon {
    color: white;
}

img.cover-img {
    object-position: top;
}

span.school.expired {
    color: red;
}